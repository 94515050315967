"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
 * WARNING!!! Any data you return here will be accessible in the frontend.
 * It will be exposed, regardless of how you use it later.
 * Only put things here if you are OK with that data being publicly available.
 *
 *                                    ████████
 *                                ████▒▒    ▒▒████
 *                              ██▓▓            ████
 *                          ▒▒██                  ████
 *                          ██                      ██
 *                  ████  ██▒▒                        ██
 *              ██▓▓    ▓▓██                          ██
 *            ██                                      ██
 *            ██            ▒▒██████▓▓                ██
 *          ██            ██          ██              ████
 *          ██          ██    ██████▒▒  ██                ██
 *      ██████        ██  ▓▓██      ████  ██                ██
 *    ██▓▓            ██  ██          ██  ██                ██
 *  ████              ██  ██          ██  ██                ██
 *  ██                ██  ██          ██  ██                ██
 *  ██                ██  ██          ██  ██                ██
 *  ██              ██████████████████████████            ██
 *    ██          ██                          ██        ████
 *      ████████████                          ██████████
 *                ██          ██████          ██
 *                ██          ██  ██          ██
 *                ██          ██████          ██
 *                ██            ██            ██
 *                ██            ██            ██
 *                ██                          ██
 *                ██                          ██
 *                ████████████████████████████▓▓
 */
exports.default = {
    assetPrefixUrl: process.env.NEXT_PUBLIC_ASSET_PREFIX_URL || "/",
    bizUrl: process.env.NEXT_PUBLIC_LEAFLY_BIZ_HOSTNAME ||
        "https://biz-integration.leafly.io",
    chartbeat: {
        enabled: process.env.NEXT_PUBLIC_CHARTBEAT_ENABLED === "true",
    },
    chatApiUrl: process.env.NEXT_PUBLIC_CHAT_API_URL || "https://chat-service.leafly.io",
    cookieDomain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || ".leafly.io",
    cookieDomainCa: process.env.NEXT_PUBLIC_COOKIE_CA_DOMAIN || ".leafly-ca.io",
    datadogClientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN || "",
    datadogEnvironment: process.env.DD_ENV || "",
    datadogRum: {
        applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || "",
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "",
        enabled: process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === "true",
        sampleRate: Number(process.env.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE || 1),
    },
    datadogVersion: process.env.DD_VERSION || "",
    deliverySearchTimeout: Number(process.env.NEXT_PUBLIC_LEAFLY_DELIVERY_SEARCH_TIMEOUT || 10000),
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_CLIENT_API_KEY || "",
    imgix: {
        cmsIntegrationUrl: process.env.NEXT_PUBLIC_IMGIX_CMS_INTEGRATION_URL ||
            "https://leafly-cms-staging.imgix.net",
        cmsProductionUrl: process.env.NEXT_PUBLIC_IMGIX_CMS_PRODUCTION_URL ||
            "https://leafly-cms-production.imgix.net",
        directusUrl: process.env.NEXT_PUBLIC_IMGIX_DIRECTUS_URL ||
            "https://directus-media-integration.leafly.io",
        imagesUrl: process.env.NEXT_PUBLIC_IMGIX_IMAGES_URL || "https://images.leafly.com",
        publicUrl: process.env.NEXT_PUBLIC_IMGIX_PUBLIC_URL ||
            "https://leafly-public.imgix.net",
    },
    janeCheckoutId: process.env.NEXT_PUBLIC_JANE_CHECKOUT_ID || "",
    janeCheckoutUrl: process.env.NEXT_PUBLIC_JANE_CHECKOUT_URL ||
        "https://staging-api.nonprod-iheartjane.com/v1/headless/embed.js",
    keepAliveSettings: {
        freeSocketTimeout: Number(process.env.NEXT_PUBLIC_KEEP_ALIVE_FREE_SOCKET_TIMEOUT || 30000),
        keepAlive: process.env.NEXT_PUBLIC_KEEP_ALIVE_ENABLED !== "false",
        maxFreeSockets: Number(process.env.NEXT_PUBLIC_KEEP_ALIVE_MAX_FREE_SOCKETS || 256),
        maxSockets: Number(process.env.NEXT_PUBLIC_KEEP_ALIVE_MAX_SOCKETS || Infinity),
        timeout: Number(process.env.NEXT_PUBLIC_KEEP_ALIVE_TIMEOUT || 60000),
    },
    leaflyCaDomain: process.env.NEXT_PUBLIC_LEAFLY_CA_DOMAIN ||
        "https://www-integration.leafly-ca.io",
    leaflyCaHostname: process.env.NEXT_PUBLIC_LEAFLY_CA_HOSTNAME ||
        "https://www-integration.leafly-ca.io",
    leaflyDomain: process.env.NEXT_PUBLIC_LEAFLY_DOMAIN ||
        "https://www-integration.leafly.io",
    leaflyHostname: process.env.NEXT_PUBLIC_LEAFLY_HOSTNAME ||
        "https://www-integration.leafly.io",
    leaflyTokeCookie: process.env.NEXT_PUBLIC_LEAFLY_TOKE_SESSION_COOKIE,
    oneSignalId: process.env.NEXT_PUBLIC_ONE_SIGNAL_ID || "",
    robots: {
        noindexAll: process.env.NEXT_PUBLIC_ROBOTS_NOINDEX_ALL === "true",
    },
    serviceRequest: {
        headers: {
            environment: String(process.env.NEXT_PUBLIC_SERVICE_REQUEST_X_ENVIRONMENT_HEADER),
        },
    },
    services: {
        apiOg: {
            url: process.env.NEXT_PUBLIC_API_OG_URL ||
                "https://api-integration.leafly.io",
        },
        apiOgInternal: {
            url: process.env.NEXT_PUBLIC_API_OG_INTERNAL_URL ||
                "https://api-og-integration.internal.leafly.io",
        },
        consumerApi: {
            url: process.env.NEXT_PUBLIC_CONSUMER_API_URL ||
                "https://consumer-api-integration.leafly.io",
        },
        consumerApiCa: {
            url: process.env.NEXT_PUBLIC_CONSUMER_API_CA_URL ||
                "https://consumer-api-integration.leafly-ca.io",
        },
        directusApi: {
            token: process.env.NEXT_PUBLIC_DIRECTUS_TOKEN || "",
            url: process.env.NEXT_PUBLIC_DIRECTUS_URL ||
                "https://directus-integration.leafly.io",
        },
        finderApi: {
            url: process.env.NEXT_PUBLIC_FINDER_API_URL ||
                "https://finder-service-integration.leafly.io",
        },
        forbiddenFruit: {
            url: process.env.NEXT_PUBLIC_FORBIDDEN_FRUIT_URL ||
                "https://chatbot-integration.leafly.io",
        },
        geoApi: {
            url: process.env.NEXT_PUBLIC_GEO_API_URL ||
                "https://www-integration.leafly.io/geo/v1/geocode",
        },
        geoIpApi: {
            url: process.env.NEXT_PUBLIC_GEO_IP ||
                "https://geo-ip-integration.leafly.io",
        },
        orderApi: {
            url: process.env.NEXT_PUBLIC_ORDER_API_URL ||
                "https://order-service-integration.leafly.io",
        },
        postalServiceApi: {
            url: process.env.NEXT_PUBLIC_POSTAL_SERVICE_API_URL ||
                "https://postal-service-integration.leafly.io",
        },
        reservationsApi: {
            url: process.env.NEXT_PUBLIC_RESERVATIONS_API ||
                "https://reservations-api-integration.leafly.io",
        },
        reservationsApiCa: {
            url: process.env.NEXT_PUBLIC_RESERVATIONS_API_CA ||
                "https://reservations-api-integration.leafly-ca.io",
        },
        sso: {
            url: process.env.NEXT_PUBLIC_SSO_URL || "https://sso-integration.leafly.io",
        },
        ssoCa: {
            url: process.env.NEXT_PUBLIC_SSO_URL_CA ||
                "https://sso-integration.leafly-ca.io",
        },
        ssoInternal: {
            url: process.env.NEXT_PUBLIC_SSO_INTERNAL_URL ||
                "https://sso-integration.internal.leafly.io",
        },
        userNotificationApi: {
            url: process.env.NEXT_PUBLIC_USER_NOTIFICATION_SERVICE_URL ||
                "https://user-notification-service-integration.leafly.io",
        },
        userNotificationCaApi: {
            url: process.env.NEXT_PUBLIC_USER_NOTIFICATION_SERVICE_CA_URL ||
                "https://user-notification-service-integration.leafly-ca.io",
        },
        userProfileApi: {
            url: process.env.NEXT_PUBLIC_USER_PROFILE_SERVICE_URL ||
                "https://user-profile-service-integration.leafly.io",
        },
        userProfileCaApi: {
            url: process.env.NEXT_PUBLIC_USER_PROFILE_SERVICE_CA_URL ||
                "https://user-profile-service-integration.leafly-ca.io",
        },
    },
    splitClientKey: process.env.NEXT_PUBLIC_SPLIT_CLIENT_KEY,
    storeLocatorMapId: process.env.NEXT_PUBLIC_GOOGLE_MAPS_STORE_LOCATOR_MAP_ID || "",
    storeSearchTimeout: Number(process.env.NEXT_PUBLIC_LEAFLY_STORE_SEARCH_TIMEOUT || 10000),
    test: {
        synchronousChat: process.env.NEXT_PUBLIC_TEST_SYNCHRONOUS_CHAT === "true",
    },
};
