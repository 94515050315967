import React, { ChangeEventHandler } from "react";
import classNames from "classnames";

import CheckIcon from "components/Icons/check_mark.svg";

type Props = Pick<HTMLInputElement, "id"> &
  Partial<
    Pick<
      HTMLInputElement,
      "checked" | "disabled" | "value" | "defaultChecked" | "name" | "readOnly"
    >
  > & {
    /**
     * Provide a `labelOrder="before"` prop to display labels before checkboxes.
     *
     * Labels are displayed after checkboxes by default.
     */
    labelOrder?: "before" | "after";
    onChange?: ChangeEventHandler<HTMLInputElement>;
    children?: React.ReactNode;
    className?: string;
  };

const Checkbox: React.FC<Props> = ({
  checked,
  children,
  disabled = false,
  labelOrder = "after",
  onChange,
  value,
  id,
  name,
  defaultChecked,
  readOnly,
  ...others
}) => {
  const classes = classNames("checkbox__label", {
    "checkbox__label--before": labelOrder === "before",
    "checkbox__label--disabled": disabled,
  });

  return (
    <label className={classes} {...others} htmlFor={id}>
      <div
        className="checkbox__checkmark-container"
        data-testid="checkbox-container"
      >
        <input
          name={name}
          className="checkbox__checkbox-input cursor-pointer focus:outline-none focus:shadow-outline"
          type="checkbox"
          id={id}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          value={value}
          defaultChecked={defaultChecked}
          readOnly={readOnly}
        />
        <div className="checkbox__checkmark">
          <CheckIcon height="20" width="20" />
        </div>
      </div>
      <span>{children}</span>
    </label>
  );
};

export default Checkbox;
