"use client";

import { PropsWithChildren } from "react";
import { useSearchParams } from "next/navigation";

/**
 * This component encapsulates the functionality that hides the header
 * and footer in a client component, which allows the parent `Layout`
 * component to remain a server component.
 */
const LayoutVisibility: React.FC<
  PropsWithChildren<{ hidden?: boolean; param: string }>
> = ({ children, hidden, param }) => {
  const params = useSearchParams();
  const paramValue = params?.get(param);

  /**
   * If there's no `hidden` value explicitly passed (usually from props
   * generated in `getServerSideProps`), then we'll check the provided
   * query parameter for a "true" value.
   */
  hidden ??= paramValue === "true";

  if (hidden) {
    return null;
  } else {
    return <>{children}</>;
  }
};

export default LayoutVisibility;
